body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    background-color: black;
}

.content {
    margin: 48px 32px 32px;
}

.content-rulebook {
    margin: 8px 32px 32px 96px;
}

@media (max-width: 480px) {
    .content {
        margin: 120px 12px 0;
    }
}

@media (min-width: 760px) {
    .content {
        margin: 76px 12px 12px 44px;
    }
}

@media (min-width: 1024px) {
    .content {
        margin: 76px 2% 2% 10%;
    }
}

@media (min-width: 1200px) {
    .content {
        margin: 76px 5% 2% 10%;
    }
}

#dice-box canvas {
    width: 100%;
    height: 100%;
}

.dice-button-short > .ant-btn-icon {
    margin-inline-end: 0 !important;
}

.content-items {
    margin: 110px 0 48px;
}

.cbx-bloodpool .ant-checkbox-wrapper {
    margin-left: 0;
}

.chq-atc {
    display: inline-block;
    position: relative;
}

.chq-atc--button__icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.chq-atc--button__icon > * {
    width: 20px;
    margin-right: 5px;
}

.chq-atc--button__children > * {
    line-height: 25px;
}

.chq-atc--button svg {
    vertical-align: text-bottom;
}

.chq-atc--button path {
    fill: #6a89af;
}

.chq-atc--dropdown {
    background-color: white;
    border-radius: 5px;
    border: 1px solid #eaeaea;
    box-shadow: 0.5px 0.5px 3px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    position: absolute;
    text-align: left;
    white-space: nowrap;
    width: 100%;
    z-index: 1;
}

.chq-atc--dropdown a {
    color: #6a89af;
    display: block;
    padding: 8px 15px;
    text-decoration: none;
}

.chq-atc--dropdown a:hover {
    background-color: #eaeaea;
}

.map-region-panel {
    float: right;
    position: fixed;
    margin: 16px;
    top: 60px;
    bottom: 16px;
    z-index: 100;
    right: 0;
    overflow-y: scroll;
}

@media (max-width: 480px) {
    .map-region-panel {
        left: 0;
    }
}

@media (min-width: 992px) {
    .map-region-panel {
        left: 50%;
    }
}

@media (min-width: 1200px) {
    .map-region-panel {
        left: 70%;
    }
}

table {
    width: 100%;
    margin-bottom: 12px;
}

table th {
    background: #ececec;
    color: black;
}

table td, table th {
    border: 1px solid lightgray;
    text-align: center;
    padding: 4px;
}

h3 {
    margin: 0 !important;
}

h4 {
    margin: 0 !important;
}

.gm-style-iw-d {
    color: black;
}

.gm-style-iw-d > div {
    display: block;
    word-break: break-word;
}

.root {
    height: 100vh;
}

.dice-roll {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
    background-color: rgba(255, 255, 255, 0.3);
    border: 1px solid white;
    border-radius: 4px;
    color: white;
    padding: 4px 8px;
}

.logo-wrapper {
    /*padding: 8px;*/
    /*position: sticky;*/
    /*top: 0;*/
    /*z-index: 1000;*/
}

.logo {
    background-image: url(../assets/logo.png);
    width: 48px;
    height: 48px;
    background-size: contain;
    background-position-x: center;
    background-repeat: no-repeat;
}

.ant-page-header {
    @media (min-width: 992px) {
        margin-left: 80px;
    }
    @media (max-width: 480px) {
        margin-left: 0;
    }
}

.ant-layout-sider {
    z-index: 1;
}

.site-layout-background {
    /*margin-left: 80px;*/
    padding: 6px 8px;
    padding-inline: 0;
    margin-block: 0;
    position: fixed;
    /*z-index: 1;*/
    width: 100%;
}

.site-page-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-color: #141414;

    /*    @media (max-width: 780px) {*/
    /*        .content {*/
    /*            margin: 8px 2px 0 72px;*/
    /*        }*/
    /*    }*/
}

.full-page-spinner {
    height: 100vh;
    align-items: center;
    display: flex;
    justify-content: center;
}

/* ANIMATIONS */
.fade-enter {
    opacity: 0.01;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.fade-leave {
    opacity: 1;
}

.fade-leave.fade-leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}

/* list items */
.item-enter {
    opacity: 0;
}

.item-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.item-exit {
    opacity: 1;
}

.item-exit-active {
    opacity: 0;
    transition: opacity 500ms ease-in;
}

/*panel slide */
.slide-enter {
    transform: translateX(100%);
}

.slide-enter-active {
    transform: translateX(0%);
    transition: transform 500ms ease-in-out;
}

.slide-exit {
    transform: translate(0%, -100%);
}

.slide-exit-active {
    transform: translate(-100%, -100%);
    transition: transform 500ms ease-in-out;
}

.dynamic-delete-button {
    position: relative;
    top: 4px;
    margin: 0 8px;
    color: red;
    font-size: 24px;
    cursor: pointer;
    transition: all 0.3s;
}

.dynamic-delete-button:hover {
    color: orangered;
}

.dynamic-delete-button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
}

/*character page*/
.attr-separator > .ant-divider-inner-text {
    color: #6027E8;
}

.attr-separator {
    /*margin: 32px 0 32px 0 !important;*/
}

.characterCardAvatar > .ant-card-meta-avatar {
    padding-right: 0 !important;
    padding-inline-end: 0 !important;
    width: 100%;
}

.anticon-arrow-left > svg {
    color: white !important;
    fill: white !important;
}

.ant-page-header-compact .ant-page-header-heading {
    flex-wrap: nowrap !important;
}

.ant-page-header .ant-page-header-heading-title {
    color: white !important;
}

/* markdown */
blockquote {
    background-color: rgba(0, 0, 0, 0.45);
    border-left: 5px solid #d0d0d0;
    margin: 1.5em 10px;
    padding: 0.5em 10px;
    font-style: italic;
}
